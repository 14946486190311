import '../styles/Navigation.css'
import logo from '../images-folder/לוגו שרגא קדרי.png'
import MobileMenu from './MobileMenu';


export const First = <><a href='/'>דף הבית</a>&nbsp; {'>'} &nbsp;</>
    export const CurrentPath = window.location.pathname;
    const CurrentPage = [];
    export function ThisPage(name) {CurrentPage.splice(0, 1, name)};
export const Last = <><a href={CurrentPath}>{CurrentPage}</a></>
    export const ParentsArray = [];
    export const Middle = [];
export function FitInMiddle(array) {
    ParentsArray.splice(0, 1, array);
    for (var i=0; i<ParentsArray[0].length-1; i+=2) {
      Middle.push(<span key={ParentsArray[0][i]}><a href={ParentsArray[0][i]}>{ParentsArray[0][i + 1]}</a>&nbsp; {'>'} &nbsp;</span>);
    }
  }
  export const TitleBarText = [];
  export function FitInTitleBar() {
    TitleBarText.splice(0, 1, <div key={""}>{First}{Middle}{Last}</div>) } 



const Navigation = () => {

  window.onload = function() {
    if (CurrentPath !== "/") {
      FitInTitleBar();
      }
    if (CurrentPath === "/" || CurrentPath === "/search" || CurrentPath === "/browse" || CurrentPath === "/images" || CurrentPath === "/about") {
      document.getElementById(CurrentPath).classList.add("underlineCurrent");
    }

  };
  

  const titleBar = 
      <div className='titleBar' id="titleBar">
    <div className='fw600 titleBarText'> 
    {TitleBarText}</div>
  </div>

  

return (
  <div>
  <MobileMenu />
<header className="header underline column">
  <div>
    <a href="/"><img src={logo} alt="" id="logo" /></a>
    <ul>  
      <li><a href="/about" id="/about">אודות</a></li>
      <li><a href="/images" id="/images">תמונות</a></li>
      <li><a href="/browse" id="/browse">עיון</a></li>
      <li><a href="/search" id="/search">חיפוש</a></li>
      <li><a href="/" id='/'>דף הבית</a></li> 
    </ul>
  </div>
{titleBar}
</header>
</div>
)

}

export default Navigation;