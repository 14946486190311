import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';

if (CurrentPath==="/omanut"){ ThisPage("אמנות");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
}

const Omanut = () => {
  SubjectFilter("אמנות")
  return (
    Page("אמנות")
  );
} 

export default Omanut;
