import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/chinuch"){ ThisPage("חינוך");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Chinuch = () => {
  SubjectFilter("חינוך")
  return (
    Page("חינוך")
  );
}

export default Chinuch;
