import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/tziyonutDatit"){ ThisPage("ציונות דתית");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const TziyonutDatit = () => {
  SubjectFilter("ציונות דתית")
  return (
    Page("ציונות דתית")
  );
}

export default TziyonutDatit;
