import React from 'react';
import "../styles/Browse.css"
import "../index.css"
import circle from "../images-folder/circleLogo.png"
import Navigation, {ThisPage, CurrentPath, FitInTitleBar} from './Navigation';


if (CurrentPath==="/browse"){ ThisPage("עיון");
 FitInTitleBar();
}

const Browse = () => {
  return (
  <div>

<Navigation />

  <div className='container'>

    <img src={circle} className='circle' alt=""/>
    <div className='fw800 title'>עיון לפי נושאים</div>
  
    <div className="cards">
 
      <a href="ishim" className='card'>
        <header className="subject">אישים</header>
        <div className="ishim" />
      </a>
      <a href="omanut" className="card">
        <header className="subject">אמנות</header>
        <div className="omanut" />
      </a>
      <a href="bikorot" className="card">
        <header className="subject">ביקורות</header>
        <div className="bikorot" />
      </a>
      <a href="bikoretAlKtavav" className="card">
        <header className="subject">ביקורת על כתביו</header>
        <div className="bikoretAlKtavav" />   
      </a>
      <a href="historia" className="card">
        <header className="subject">היסטוריה</header>
        <div className="historia" />
      </a>
      <a href="hespedim" className="card">
        <header className="subject">הספדים</header>
        <div className="hespedim" />
      </a>
      <a href="chevra" className="card">
        <header className="subject">חברה</header>
        <div className="chevra" />
      </a>
      <a href="chinuch" className="card">
        <header className="subject">חינוך</header>
        <div className="chinuch" />
      </a>
      <a href="yahadut" className="card">
        <header className="subject">יהדות</header>
        <div className="yahadut" />
      </a>
      <a href="yiddish" className="card">
        <header className="subject">יידיש</header>
        <div className="yiddish" />
      </a>
      <a href="mediniyut" className="card">
        <header className="subject">מדיניות</header>
        <div className="mediniyut" />
      </a>
      <a href="michtavim" className="card">
        <header className="subject">מכתבים</header>
        <div className="michtavim" />
      </a>
      <a href="sifrut" className="card">
        <header className="subject">ספרות</header>
        <div className="sifrut" />
      </a>
      <a href="avodaSotzialit" className="card">
        <header className="subject">עבודה סוציאלית</header>
        <div className="avodaSotzialit" />
      </a>
      <a href="filosofia" className="card">
        <header className="subject">פילוסופיה</header>
        <div className="filosofia" />
      </a>
      <a href="tziyonut" className="card">
        <header className="subject">ציונות</header>
        <div className="tziyonut" />
      </a>
      <a href="tziyonutDatit" className="card">
        <header className="subject">ציונות דתית</header>
        <div className="tziyonutDatit" />
      </a>
      <a href="rishmeyMasa" className="card">
        <header className="subject">רשמי מסע</header>
        <div className="rishmeyMasa"/>
      </a>
      <a href="nispachim" className="card">
          <header className="subject">נספחים</header>
          <div className="nispachim" />
      </a>
    </div>

    </div>
  </div>
  );
}

export default Browse;
