
export const driveUrl = "https://drive.google.com/file/d/";

const Error404 = "1BZhDmH4EEdmEpS_VuX85S5sgm6P0yYPo";

const Articles = {
        '001': "1rLDuPSnuiOdDBn0w6dnnd3--kjeu-c3F",
        '002': "1EpZvuuMtcq_aARsZSSc5iNzEplVCxhXp",
        '003': "1m7GwRFmetSfx0odiRPEJY7NK0bU9fRAZ",
        '004': "1rhv0lun5d6_iLItQPZjdG8VTMrj-wHo7",
        '005': "1zKduVQb2aP9dLP2-n8gc71LFqKnqHbPr",
        '006': "10PSgxq5pTzvyz9R_JT1zpbHZXWJDPg-t",
        '007': "1UY2waVornbNnvTlHcGasel3DXQ8npEE8",
        '008': Error404,
        '009': Error404,
        '010': Error404,
        '011': Error404,
        '012': Error404,
        '013': Error404,
        '014': Error404,
        '015': Error404,
        '016': Error404,
        '017': Error404,
        '018': Error404,
        '019': Error404,
        '020': Error404,
        '021': Error404,
        '022': Error404,
        '023': Error404,
        '024': Error404,
        '025': Error404,
        '026': Error404,
        '027': Error404,
        '028': Error404,
        '029': Error404,
        '030': Error404,
        '031': Error404,
        '032': Error404,
        '033': Error404,
        '034': Error404,
        '035': Error404,
        '036': Error404,
        '037': Error404,
        '038': Error404,
        '039': Error404,
        '040': Error404,
        '041': Error404,
        '042': Error404,
        '043': Error404,
        '044': Error404,
        '045': Error404,
        '046': Error404,
        '047': Error404,
        '048': Error404,
        '049': Error404,
        '050': Error404,
        '051': Error404,
        '052': Error404,
        '053': Error404,
        '054': Error404,
        '055': Error404,
        '056': Error404,
        '057': Error404,
        '058': Error404,
        '059': Error404,
        '060': Error404,
        '061': Error404,
        '062': Error404,
        '063': Error404,
        '064': Error404,
        '065': Error404,
        '066': Error404,
        '067': Error404,
        '068': Error404,
        '069': Error404,
        '070': Error404,
        '071': Error404,
        '072': Error404,
        '073': Error404,
        '074': Error404,
        '075': Error404,
        '076': Error404,
        '077': Error404,
        '078': Error404,
        '079': Error404,
        '080': Error404,
        '081': Error404,
        '082': Error404,
        '083': Error404,
        '084': Error404,
        '085': Error404,
        '086': Error404,
        '087': Error404,
        '088': Error404,
        '089': Error404,
        '090': Error404,
        '091': Error404,
        '092': Error404,
        '093': Error404,
        '094': Error404,
        '095': Error404,
        '096': Error404,
        '097': Error404,
        '098': Error404,
        '099': Error404,
        100: Error404,
        101: Error404,
        102: Error404,
        103: Error404,
        104: Error404,
        105: Error404,
        106: Error404,
        107: Error404,
        108: Error404,
        109: Error404,
        110: Error404,
        111: Error404,
        112: Error404,
        113: Error404,
        114: Error404,
        115: Error404,
        116: Error404,
        117: Error404,
        118: Error404,
        119: Error404,
        120: Error404,
        121: Error404,
        122: Error404,
        123: Error404,
        124: Error404,
        125: Error404,
        126: Error404,
        127: Error404,
        128: Error404,
        129: Error404,
        130: Error404,
        131: Error404,
        132: Error404,
        133: Error404,
        134: Error404,
        135: Error404,
        136: Error404,
        137: Error404,
        138: Error404,
        139: Error404,
        140: Error404,
        141: Error404,
        142: Error404,
        143: Error404,
        144: Error404,
        145: Error404,
        146: Error404,
        147: Error404,
        148: Error404,
        149: Error404,
        150: Error404,
        151: Error404,
        152: Error404,
        153: Error404,
        154: Error404,
        155: Error404,
        156: Error404,
        157: Error404,
        158: Error404,
        159: Error404,
        160: Error404,
        161: Error404,
        162: Error404,
        163: Error404,
        164: Error404,
        165: Error404,
        166: Error404,
        167: Error404,
        168: Error404,
        169: Error404,
        170: Error404,
        171: Error404,
        172: Error404,
        173: Error404,
        174: Error404,
        175: Error404,
        176: Error404,
        177: Error404,
        178: Error404,
        179: Error404,
        180: Error404,
        181: Error404,
        182: Error404,
        183: Error404,
        184: Error404,
        185: Error404,
        186: Error404,
        187: Error404,
        188: Error404,
        189: Error404,
        190: Error404,
        191: Error404,
        192: Error404,
        193: Error404,
        194: Error404,
        195: Error404,
        196: Error404,
        197: Error404,
        198: Error404,
        199: Error404,
        200: Error404,
        201: Error404,
        202: Error404,
        203: Error404,
        204: Error404,
        205: Error404,
        206: Error404,
        207: Error404,
        208: Error404,
        209: Error404,
        210: Error404,
        211: Error404,
        212: Error404,
        213: Error404,
        214: Error404,
        215: Error404,
        216: Error404,
        217: Error404,
        218: Error404,
        219: Error404,
        220: Error404,
        221: Error404,
        222: Error404,
        223: Error404,
        224: Error404,
        225: Error404,
        226: Error404,
        227: Error404,
        228: Error404,
        229: Error404,
        230: Error404,
        231: Error404,
        232: Error404,
        233: Error404,
        234: Error404,
        235: Error404,
        236: Error404,
        237: Error404,
        238: Error404,
        239: Error404,
        240: Error404,
        241: Error404,
        242: Error404,
        243: Error404,
        244: Error404,
        245: Error404,
        246: Error404,
        247: Error404,
        248: Error404,
        249: Error404,
        250: Error404,
        251: Error404,
        252: Error404,
        253: Error404,
        254: Error404,
        255: Error404,
        256: Error404,
        257: Error404,
        258: Error404,
        259: Error404,
        260: Error404,
        261: Error404,
        262: Error404,
        263: Error404,
        264: Error404,
        265: Error404,
        266: Error404,
        267: Error404,
        268: Error404,
        269: Error404,
        270: Error404,
        271: Error404,
        272: Error404,
        273: Error404,
        274: Error404,
        275: Error404,
        276: Error404,
        277: Error404,
        278: Error404,
        279: Error404,
        280: Error404,
        281: Error404,
        282: Error404,
        283: Error404,
        284: Error404,
        285: Error404,
        286: Error404,
        287: Error404,
        288: Error404,
        289: Error404,
        290: Error404,
        291: Error404,
        292: Error404,
        293: Error404,
        294: Error404,
        295: Error404,
        296: Error404,
        297: Error404,
        298: Error404,
        299: Error404,
        300: Error404,
        301: Error404,
        302: Error404,
        303: Error404,
        304: Error404,
        305: Error404,
        306: Error404,
        307: Error404,
        308: Error404,
        309: Error404,
        310: Error404,
        311: Error404,
        312: Error404,
        313: Error404,
        314: Error404,
        315: Error404,
        316: Error404,
        317: Error404,
        318: Error404,
        319: Error404,
        320: Error404,
        321: Error404,
        322: Error404,
        323: Error404,
        324: Error404,
        325: Error404,
        326: Error404,
        327: Error404,
        328: Error404,
        329: Error404,
        330: Error404,
        331: Error404,
        332: Error404,
        333: Error404,
        334: Error404,
        335: Error404,
        336: Error404,
        337: Error404,
        338: Error404,
        339: Error404,
        340: Error404,
        341: Error404,
        342: Error404,
        343: Error404,
        344: Error404,
        345: Error404,
        346: Error404,
        347: Error404,
        348: Error404,
        349: Error404,
        350: Error404,
        351: Error404,
        352: Error404,
        353: Error404,
        354: Error404,
        355: Error404,
        356: Error404,
        357: Error404,
        358: Error404,
        359: Error404,
        360: Error404,
        361: Error404,
        362: Error404,
        363: Error404,
        364: Error404,
        365: Error404,
        366: Error404,
        367: Error404,
        368: Error404,
        369: Error404,
        370: Error404,
        371: Error404,
        372: Error404,
        373: Error404,
        374: Error404,
        375: Error404,
        376: Error404,
        377: Error404,
        378: Error404,
        379: Error404,
        380: Error404,
        381: Error404,
        382: Error404,
        383: Error404,
        384: Error404,
        385: Error404,
        386: Error404,
        387: Error404,
        388: Error404,
        389: Error404,
        390: Error404,
        391: Error404,
        392: Error404,
        393: Error404,
        394: Error404,
        395: Error404,
        396: Error404,
        397: Error404,
        398: Error404,
        399: Error404,
        400: "19lFIHxBszf0jrLNYJ8gh569wi0-WVxns",
        401: "1iu47yw31u6QmIfq3OivPCnvWUnj0eKwa",
        402: "1J1ZU0Et4teDGDue4NrNI4XrskejJ-xnI",
        403: Error404,
        404: "1snotoZQXo1WPDqgeMpUlAg0uICSZtAEy",
        405: "1ThN8Jjo9knSxDbK--tloA8OCvXFkLUqe",
        406: Error404,
        407: Error404,
        408: Error404,
        409: Error404,
        410: Error404,
        411: Error404,
        412: Error404,
        413: "1b7NvDi9aV-3zSHcvracVCSGkhTBiXge6",
        414: "17ZFs0cmVifjZLeJj40ZeBkAh4mjAerO4",
        415: "13LpKJYghB5LvMydGQB6qjXHf4nbRfjGU",
        416: Error404,
        417: Error404,
        418: "1eaWdADjA6rfU-IHLY2lUjJDEeBLFjojt",
        419: "1O1h_UUFCH7-3xqbjXO1KKXaDLpAf4bNH",
        420: "1KbDPj3eCdqHcnZPIjFc8owdEukB4b0XV",
        421: "1lNrBemLQT2q3j2rn4GOqpPDqpyNJoIsu",
        422: Error404,
        423: "1WeMy5EKyb71480nyqcjbdTqp99UKAW_q",
        424: Error404,
        425: Error404,
        426: Error404,
        427: Error404,
        428: Error404,
        429: Error404,
        430: "1jfqkPNOeyCIfg-WTJUdXNRiiCLQ8ZKNs",
        431: Error404,
        432: "1SeoR9hubJ_plIDZEO5_2yWh5EYm38Ggq",
        433: "1q906YCiMcJrGodET0lzfYPtbafW8WCIM",
        434: Error404,
        435: Error404,
        436: "12-buv9H1XofQwIuYGerBQ5tst24dugE1",
        437: "1wHGBpuCtSiSJaCxuEKnCvblSxOt6qZVQ",
        438: Error404,
        439: Error404,
        440: Error404,
        441: "1T-UWp5CazsW7_WZOQJBAW0v5_eYUeBZs",
        442: Error404,
        443: "1rUe6Y_sxFcgBcQliSva6yQxB7ciCpPo9",
        444: Error404,
        445: "11wD9y2WX6eAGihtRlHr71rzNLRLuTbhN",
        446: Error404,
        447: Error404,
        448: Error404,
        449: Error404,
        450: "10Kd8v8M4B1yrQL9P-ZCI85d9TBeWwDR7",
        451: Error404,
        452: Error404,
        453: Error404,
        454: Error404,
        455: Error404,
        456: Error404,
        457: Error404,
        458: Error404,
        459: Error404,
        460: Error404,
        461: Error404,
        462: Error404,
        463: Error404,
        464: Error404,

      };


export default Articles;