import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/sifrut"){ ThisPage("ספרות");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Sifrut = () => {
  SubjectFilter("ספרות")
  return (
    Page("ספרות")
  );
}

export default Sifrut;
