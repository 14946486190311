import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/tziyonut"){ ThisPage("ציונות");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Tziyonut = () => {
  SubjectFilter("ציונות")
  return (
    Page("ציונות")
  );
}

export default Tziyonut;
