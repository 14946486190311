import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/ishim"){ ThisPage("אישים");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Ishim = () => {
  SubjectFilter("אישים")
  return (
    Page("אישים")
  );
}

export default Ishim;
