import "../styles/MobileMenu.css"
import logo from '../images-folder/לוגו שרגא קדרי.png'

const MobileMenu = () => {
    
    return(
    
    <div className="container1">

        <input id="toggle" type="checkbox" />
        <label className="toggle-container" htmlFor="toggle">
        <span className="button button-toggle"></span>
        </label>

        <nav className="nav">
        <a className="nav-item" href="/">בית&nbsp;&nbsp;</a>
        <a className="nav-item" href="/search">חיפוש</a>
        <a className="nav-item" href="/browse">עיון&nbsp;</a>
        <a className="nav-item" href="/images">תמונות</a>
        <a className="nav-item" href="/about">אודות</a>
        </nav>

        <a href="/"><img src={logo} alt="" id="logoMobile" /></a>
  </div>
    )
}

export default MobileMenu;