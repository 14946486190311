import React from 'react';
import '../index.css'
import circle from "../images-folder/circleLogo.png"
import Navigation, {ThisPage, CurrentPath, FitInTitleBar} from './Navigation';


if (CurrentPath==="/images"){ ThisPage("תמונות");
 FitInTitleBar();
}

const Images = () => {
  return (
    <div>

    <Navigation />

      <div className='container'>
        <img src={circle} className='circle' alt=""/>
        <div className='fw800 title'>תמונות</div>
      </div>
    </div>
  );
}

export default Images;
