import React from 'react';
import "../styles/Homepage.css"
import cover from "../images-folder/coverVideo.mp4"
import old from "../images-folder/old.svg"
import reading from "../images-folder/reading.svg"
import detective from "../images-folder/detective.svg"
import Navigation from './Navigation';



const Homepage = () => {
  return (
    <div>

    <Navigation />
    
    <div className='relative'>

      <video id="cover" loop autoPlay muted >
        <source src={cover} type="video/mp4" /> 
      </video>

      <div className='fw800 homepageTitle'>
        שרגא קדרי - הסופר ויצירתו <br/> ━━━
        <div className='fw600 subtitle'> 
        "לדעתי, הסופר - שליח הוא... כל יצירה, אם גדולה היא ואם שליחות היא, הריהי תפילה. ומה תכנה של התפילה? לגלות את המאור שביקום ולהלחם ברע"
        </div>
      </div>

      <div className='statistics fw600'>
        <div>
            <img src={old} alt="" className='svg'/> &nbsp;
            52
            שנות יצירה
        </div>
        <div>
            <img src={reading} alt="" className='svg'/>&nbsp;&nbsp;
            484
            קטעי עיתונות
        </div>
        <div>
            <img src={detective} alt="" className='svg'/>&nbsp;&nbsp;
            336
            מילות מפתח
        </div>

      </div>
    </div>

    </div>
  );
}

export default Homepage;
