import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/historia"){ ThisPage("היסטוריה");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Historia = () => {
  SubjectFilter("היסטוריה")
  return (
    Page("היסטוריה")
  );
}

export default Historia;
