import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import Search from './components/Search';
import About from './components/About';
import Browse from './components/Browse';
import Images from './components/Images';
import Ishim from './components/browseCategories/Ishim';
import Omanut from './components/browseCategories/Omanut';
import Bikorot from './components/browseCategories/Bikorot';
import BikoretAlKtavav from './components/browseCategories/BikoretAlKtavav';
import Historia from './components/browseCategories/Historia';
import Hespedim from './components/browseCategories/Hespedim';
import Chevra from './components/browseCategories/Chevra';
import Chinuch from './components/browseCategories/Chinuch';
import Yahadut from './components/browseCategories/Yiddish';
import Yiddish from './components/browseCategories/Yiddish';
import Mediniyut from './components/browseCategories/Mediniyut';
import Michtavim from './components/browseCategories/Michtavim';
import Sifrut from './components/browseCategories/Sifrut';
import AvodaSotzialit from './components/browseCategories/AvodaSotzialit';
import Filosofia from './components/browseCategories/Filosofia';
import Tziyonut from './components/browseCategories/Tziyonut';
import TziyonutDatit from './components/browseCategories/TziyonutDatit';
import RishmeyMasa from './components/browseCategories/RishmeyMasa';
import Nispachim from './components/browseCategories/Nispachim';


const App = () => {

  return (
    <div>

      <Router>
          <Routes> 
            <Route path="/" element={<Homepage/>} />
            <Route path="/search" element={<Search/>} />
            <Route path="/browse" element={<Browse/>} />
            <Route path="/images" element={<Images/>} />
            <Route path="/about" element={<About/>} />  

            <Route path="/ishim" element={<Ishim/>} /> 
            <Route path="/omanut" element={<Omanut/>} />
            <Route path="/bikorot" element={<Bikorot/>} />
            <Route path="/bikoretAlKtavav" element={<BikoretAlKtavav/>} />
            <Route path="/historia" element={<Historia/>}/>
            <Route path="/hespedim" element={<Hespedim/>}/>
            <Route path="/chevra" element={<Chevra/>}/>
            <Route path="/chinuch" element={<Chinuch/>}/>
            <Route path="/yahadut" element={<Yahadut/>}/>
            <Route path="/yiddish" element={<Yiddish/>}/>
            <Route path="/mediniyut" element={<Mediniyut/>} />
            <Route path="/michtavim" element={<Michtavim/>} />
            <Route path="/sifrut" element={<Sifrut/>} />
            <Route path="/avodaSotzialit" element={<AvodaSotzialit/>} />
            <Route path="/filosofia" element={<Filosofia/>} />
            <Route path="/tziyonut" element={<Tziyonut/>} />
            <Route path="/tziyonutDatit" element={<TziyonutDatit/>} />
            <Route path="/rishmeyMasa" element={<RishmeyMasa/>} />
            <Route path="/nispachim" element={<Nispachim/>} />            
          </Routes>    
      </Router>
    </div>
  );
}

export default App;
