import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/mediniyut"){ ThisPage("מדיניות");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Mediniyut = () => {
  SubjectFilter("מדיניות")
  return (
    Page("מדיניות")
  );
}

export default Mediniyut;
