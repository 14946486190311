import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, RefinementList, Stats } from 'react-instantsearch-hooks-web';
import '../styles/Search.css'
import "../index.css"
import circle from "../images-folder/circleLogo.png"
import Navigation, {ThisPage, CurrentPath, FitInTitleBar} from './Navigation';
import Articles, { driveUrl } from './Articles';



if (CurrentPath==="/search"){ ThisPage("חיפוש");
 FitInTitleBar();
}


export const Hit = ({ hit }) => {

  return (

    <article id={hit.subject} className="hitsArticle" onClick={
          function() { window.open(driveUrl+Articles[hit.objectID]); }}>
      <div className='hitCategory'>{hit.category}:</div>
      <div className='hitName'>{hit.name}</div>
      <div className='hitNewspaper'>עיתון {hit.newspaper}</div>
      <div className='hitDates'>{hit.hebrewDate}, {hit.date}</div>
      <div className='hitTags'>{hit.tags}</div>
    </article>

  );
}

export const searchClient = algoliasearch('VB2J49009N', '110ca88f1a276e3ac054b674273aaf5e');

const Search = () => {


const [open, setOpen] = React.useState(false);
const [bgBlue, bgBlueClicked] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
    bgBlueClicked(!bgBlue);
  };
  return (
    <div >
 
    <Navigation />

      <div className='container'>
        <img src={circle} className='circle' alt=""/>
        <div className='fw800 title'>חיפוש</div>
      </div>


      <InstantSearch searchClient={searchClient} indexName="Search">
        <div className='container-header'>
            <SearchBox  
                placeholder="  חפשו מאמרים, סיפורים, תמונות ועוד" 
                resetIconComponent={({ classNames }) => (<div className={classNames.resetIcon}></div>)}
                submitIconComponent={({ classNames }) => (<div className={classNames.submitIcon}></div>)}
              />
            <Stats className='stats'/>         
        </div>
       
      <button  id="focusSearchBtn" className={bgBlue ? 'bgBlueClicked' : 'bgBlue'} onClick={handleOpen}>
            <span className="dropdown-arrow" />
            <span> מיקוד החיפוש</span>
      </button>

      {open ? 
    <div className='focusSearchCard'> 
      <div className='unit'>
          <div className='panel-header'>
            <span>עיתון</span>
          </div>
          <div className='ais-Panel-body'>
            <RefinementList attribute="newspaper" showMore={true}/>
          </div>
      </div>
      <div className='unit'>
          <div className='panel-header'>
            <span>עשור</span>
          </div>
          <div className='ais-Panel-body'>
            <RefinementList attribute="decade" />
          </div>
      </div>
      <div className='unit'>
          <div className='panel-header'>
            <span>נושא</span>
          </div>
          <div className='ais-Panel-body'>
            <RefinementList attribute="subject" showMore={true} />  
          </div>
      </div>
         
      </div>
     : <div></div>}

      <div className="container-search"> 
        <div className='results'> 
    
          <div className='hits'>    
            <Hits hitComponent={Hit}/>
          </div> 
      
        </div>
      </div>

    </InstantSearch> 
  </div>
  );
}



export default Search;