import React from 'react';
import "../../index.css";
import circle from "../../images-folder/circleLogo.png";
import { Hits, InstantSearch } from "react-instantsearch-hooks-web";
import { Hit, searchClient } from "../Search";
import Navigation from "../Navigation";

export function SubjectFilter(subjectName){

    function RemoveSubjects(){
      const myNodelist = document.querySelectorAll("article");
      myNodelist.forEach(element => {
        if (element.id!==subjectName){
        element?.parentElement.remove()
      }});
    }
    function step(timestamp) {
      RemoveSubjects()
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}

const Results = () => {
return(

    <InstantSearch searchClient={searchClient} indexName="Search" >
    <div className="container-search"> 
    <div className='results paddtop50'>   
        <div className='hits'>        
        <Hits hitComponent={Hit}/>
        </div> 
    </div>
    </div>
    </InstantSearch>
)
}

export const Page = (pageName) => {
return(
    <div>
        <Navigation />
        <div className='container'>
            <img src={circle} className='circle' alt=""/>
            <div className='fw800 title'>{pageName}</div>
        </div>
        <Results/>
    </div>
)
}