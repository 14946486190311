import React from 'react';
import '../index.css'
import circle from "../images-folder/circleLogo.png"
import Navigation, {ThisPage, CurrentPath, FitInTitleBar} from './Navigation';
 
if (CurrentPath==="/about"){ ThisPage("אודות");
 FitInTitleBar();
}
const About = () => {
  return (
    <div>

    <Navigation />

      <div className='container'>
        <img src={circle} className='circle' alt=""/>
        <div className='fw800 title'>אודות</div>
      </div>
      
    </div>

  );
}

export default About;
