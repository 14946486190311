import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/nispachim"){ ThisPage("נספחים");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Nispachim = () => {
  SubjectFilter("נספחים")
  return (
    Page("נספחים")
  );
}

export default Nispachim;
