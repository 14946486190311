import {ThisPage, FitInMiddle, CurrentPath, FitInTitleBar} from '../Navigation';
import { Page, SubjectFilter } from './CompsForCategories';


if (CurrentPath==="/yiddish"){ ThisPage("יידיש");
  let Parents = ["/browse", "עיון"]; FitInMiddle(Parents);
  FitInTitleBar();
  }


const Yiddish = () => {
  SubjectFilter("יידיש")
  return (
    Page("יידיש")
  );
}

export default Yiddish;
